<template>
  <div style="background-color: #FFFFFF;height:100%">
    <v-navigation-drawer
      v-if="userIsLoggedIn"
      width="252"
      class="navigation-menu"
      style="background-color: #f9f9f9"
      clipped
      app
    >
      <v-layout
        fill-height
        column
        justify-space-between
      >
        <v-list
          class="pt-0 list-menu"
          style="background: inherit !important;"
          dense
        >
          <v-list-tile />

          <v-list-tile
            :to="{name: 'candidate:offers'}"
            active-class="menu-highlight"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Ofertas
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            :to="{name: 'candidate:profile:applications'}"
            active-class="menu-highlight"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>mdi-account-plus-outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Mis Postulaciones
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            :to="{name: 'candidate:profile:favourites'}"
            active-class="menu-highlight"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>far fa-bookmark</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Mis Favoritos
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            :to="{name: 'candidate:profile:detail'}"
            active-class="menu-highlight"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Mi Perfil
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            href="https://mandomedio.com/outplacement-personas/"
            target="_blank"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Asesoría Empleabilidad
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            href="https://mandomedio.com/tips/"
            target="_blank"
            class="list-tile-margin"
          >
            <v-list-tile-action>
              <v-icon>mdi-briefcase-variant-outline</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-tile-title"
              >
                Tips y Consejos
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-btn
          v-if="false"
          color="success"
          depressed
          small
        >
          <v-icon left>
            mdi-headset
          </v-icon>
          Ayuda y Soporte
        </v-btn>
      </v-layout>
    </v-navigation-drawer>
    <v-container
      v-if="!application_completed"
      style="max-width: 820px"
    >
      <v-flex
        xs12
      >
        <v-layout align-center>
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="mr-3 mb-3"
          >
            <v-avatar
              color="#444444"
              size="61"
              tile
            >
              <v-icon
                x-large
                color="white"
              >
                {{ jobIcon() }}
              </v-icon>
            </v-avatar>
          </div>
          <p
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-postulation' : 'title-postulation-responsive'"
          >
            Postula a {{ offer.computed_title }}
          </p>
        </v-layout>
      </v-flex>
      <v-flex>
        <v-stepper
          v-model="steps"
          non-linear
          class="elevation-0"
          style="border-radius: 15px"
        >
          <v-stepper-header>
            <v-stepper-step
              editable
              step="1"
              color="info2"
              class="title-basic-information"
            >
              Información básica
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="first_step_complete"
              step="2"
              color="info2"
              class="title-basic-information"
            >
              Preguntas
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="!cantApply && !hasAnswers"
              step="3"
              color="info2"
              class="title-basic-information"
            >
              Vista previa
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <v-card
          v-show="steps === '1'"
          class="card-postulation"
          :style="$vuetify.breakpoint.smAndUp ? 'margin-top: 35px' : 'margin-top: 20px'"
        >
          <v-layout>
            <v-container style="max-width: 730px">
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs12
                  mt-2
                >
                  <div class="notification_postulation">
                    <v-layout>
                      <v-flex
                        xs1
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'margin-left: 30px'
                          : 'margin-left: 10px'"
                      >
                        <v-icon
                          :medium="$vuetify.breakpoint.smAndUp"
                          color="#30B0BF"
                          :style="$vuetify.breakpoint.smAndUp
                            ? 'margin-top: 25px'
                            : 'margin-top: 15px'"
                        >
                          far fa-clipboard-list
                        </v-icon>
                      </v-flex>
                      <v-flex
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'padding-top: 16px;padding-bottom: 16px'
                          : 'padding: 16px'"
                      >
                        <p
                          :class="$vuetify.breakpoint.smAndUp
                            ? 'text-notification-company' : 'text-notification-company-responsive'"
                        >
                          Completa tu perfil para que las empresas
                          puedan ver toda tu información. <br>Así tendras más
                          posibilidades de que te contacten
                        </p>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex mt-4>
                  <p
                    class="title-card"
                    :style="$vuetify.breakpoint.smAndUp
                      ? 'font-size: 24px' : 'font-size: 20px'"
                  >
                    Información básica
                  </p>
                </v-flex>
                <v-flex
                  xs12
                  mt-3
                  :style="nationalityErrors.length > 0 ? 'margin-bottom: 13px' : ''"
                >
                  <CountryPicker
                    v-model="country"
                    label="País de Residencia"
                    class="inputs"
                    full-width
                    :clearable="false"
                    :default-to-chile="false"
                    :error-messages="nationalityErrors"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mt-3
                >
                  <RegionPicker
                    :key="country"
                    v-model="region"
                    label="Región"
                    class="inputs"
                    full-width
                    :clearable="false"
                    :country="country"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mt-3
                  :style="cityErrors.length > 0 ? 'margin-bottom: 13px' : ''"
                >
                  <CityPicker
                    :key="region"
                    v-model="city"
                    :region="region"
                    label="Ciudad o Comuna"
                    class="inputs"
                    full-width
                    :clearable="false"
                    :error-messages="cityErrors"
                  />
                </v-flex>
                <v-flex
                  xs12
                  mt-3
                >
                  <v-text-field
                    v-model="phone_number"
                    label="Teléfono"
                    type="tel"
                    name="phone_number"
                    :prefix="numberPrefix"
                    autocomplete="phone_number"
                    :error-messages="phoneErrors"
                    full-width
                    outline
                    style="margin: 0px"
                  />
                </v-flex>
                <v-layout
                  align-center
                  justify-center
                  column
                  :class="cvError === true ? 'upload-cv-error' : 'upload-cv'"
                  @click="pickCvFile"
                >
                  <v-flex
                    xs6
                    mt-4
                    style="height: 20px"
                  >
                    <v-btn
                      style="margin: 0px"
                      icon
                    >
                      <v-icon
                        icon
                        :color="cvError === true ? '#FF7573' : '#30B0BF'"
                        size="21"
                      >
                        far fa-clone
                      </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6>
                    <p
                      v-if="!cvName"
                      :class="cvError === true ? 'upload-cv-text-error' : 'upload-cv-text'"
                      style="cursor: pointer;"
                    >
                      Subir curriculum
                    </p>
                    <p
                      v-else
                      class="upload-cv-text"
                      style="cursor: pointer;"
                    >
                      {{ cvName }}
                    </p>
                    <input
                      ref="cvFileField"
                      type="file"
                      style="display: none; cursor: pointer;"
                      accept="*"
                      @change="onCvFilePicked"
                    >
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout
                v-if="$vuetify.breakpoint.smAndUp"
                row
                justify-space-between
              >
                <v-flex
                  v-if="cvError === true"
                  xs12
                >
                  <p
                    class="formnat-cv-error"
                  >
                    Debe subir un CV.
                  </p>
                </v-flex>
                <v-flex
                  xs12
                >
                  <p
                    class="formnat-cv"
                  >
                    Los formatos permitidos son:  PDF peso max 2mb
                  </p>
                </v-flex>
              </v-layout>
              <v-layout
                v-else
                row
                wrap
                justify-start
              >
                <v-flex
                  v-if="cvError === true"
                  xs12
                >
                  <p
                    class="formnat-cv-responsive-error"
                  >
                    Debe subir un CV.
                  </p>
                </v-flex>
                <v-flex
                  xs12
                >
                  <p
                    class="formnat-cv-responsive"
                  >
                    Los formatos permitidos son:  PDF peso max 2mb
                  </p>
                </v-flex>
              </v-layout>
              <template v-if="$vuetify.breakpoint.smAndUp">
                <v-layout
                  justify-space-between
                  mt-5
                >
                  <v-flex xs10>
                    <p
                      style="cursor: pointer;"
                      class="delete-text"
                      @click="cancelApplication('Personal Data')"
                    >
                      <v-btn
                        style="margin-right: 0px;"
                        icon
                      >
                        <v-icon
                          style="margin: 0px; margin-bottom: 5px"
                          icon
                          color="#E57373"
                          size="18"
                        >
                          far fa-trash-alt
                        </v-icon>
                      </v-btn>
                      Cancelar postulación
                    </p>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      color="#30B0BF"
                      style="border-radius: 6px;"
                      large
                      @click="submitDetails()"
                    >
                      <p class="save-text">
                        Continuar
                      </p>
                      <v-icon
                        right
                        color="#FFFFFF"
                        size="20px"
                      >
                        far fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    mt-5
                  >
                    <v-btn
                      color="#30B0BF"
                      style="border-radius: 6px;"
                      large
                      block
                      @click="submitDetails()"
                    >
                      <p class="save-text">
                        Continuar
                      </p>
                      <v-icon
                        right
                        color="#FFFFFF"
                        size="20px"
                      >
                        far fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout justify-center>
                      <p
                        style="cursor: pointer;"
                        class="delete-text"
                        @click="cancelApplication('Personal Data')"
                      >
                        <v-btn
                          style="margin-right: 0px;"
                          icon
                        >
                          <v-icon
                            style="margin: 0px; margin-bottom: 5px"
                            icon
                            color="#E57373"
                            size="18"
                          >
                            far fa-trash-alt
                          </v-icon>
                        </v-btn>
                        Cancelar postulación
                      </p>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </v-container>
          </v-layout>
        </v-card>
        <v-card
          v-show="steps === '2'"
          class="card-postulation"
          :style="$vuetify.breakpoint.smAndUp ? 'margin-top: 35px' : 'margin-top: 20px'"
        >
          <v-layout>
            <v-container
              style="max-width: 730px;"
            >
              <v-layout
                row
                wrap
              >
                <v-flex mt-4>
                  <p
                    class="title-card"
                    :style="$vuetify.breakpoint.smAndUp
                      ? 'font-size: 24px' : 'font-size: 20px'"
                  >
                    Preguntas
                  </p>
                </v-flex>
                <v-container>
                  <v-layout wrap>
                    <v-layout
                      v-if="!hasWageQuestion"
                      wrap
                    >
                      <v-flex
                        xs12
                      >
                        <p
                          class="title-questions"
                          :style="$vuetify.breakpoint.smAndUp
                            ? 'font-size: 20px' : 'font-size: 15px'"
                        >
                          Pretensión de Renta
                        </p>
                      </v-flex>
                      <v-flex
                        xs3
                      >
                        <v-text-field
                          v-model="wages.min"
                          mask="#########"
                          label=""
                          :prefix="currencyPrefix"
                          style="margin: 0px"
                        />
                      </v-flex>
                    </v-layout>
                    <v-flex
                      v-for="question_obj in questions"
                      :key="question_obj.uuid"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ question_obj.question }}
                      </p>
                      <v-textarea
                        v-model="question_obj.answer"
                        outline
                        required
                        style="margin-top: 7px"
                      />
                    </v-flex>

                    <v-flex
                      v-for="(rq, index) in offer.range_questions"
                      :key="`range_questions-${index}`"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ rq.text }}
                      </p>
                      <v-radio-group
                        v-if="rq.options.length <= 2"
                        v-model="rq.yesno_option"
                        row
                      >
                        <v-radio
                          v-for="(option, idx) in rq.options"
                          :key="`range_questions_${index}_option_${idx}`"
                          :label="option.text"
                          :value="option.pk"
                          :class="$vuetify.breakpoint.smAndUp ? 'title-questions mr-5' : 'mr-5'"
                          :style="$vuetify.breakpoint.smAndUp
                            ? 'font-size: 20px' : 'font-size: 15px'"
                          color="info2"
                        />
                      </v-radio-group>
                      <v-slider
                        v-else
                        v-model="rq.likert_option"
                        :tick-labels="rq.options.map(data => data.text)"
                        :max="rq.options.length - 1"
                        step="1"
                        ticks="always"
                        tick-size="2"
                        color="info2"
                      />
                    </v-flex>
                    <v-flex
                      v-for="(rq, index) in offer.multi_choice_questions"
                      :key="`multi_choice_questions-${index}`"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions mb-3"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ rq.text }}
                      </p>
                      <div
                        v-for="(row, idoptions) in rq.multi_options"
                        :key="`multiple_question_${index}_multi_options_${idoptions}`"
                      >
                        <v-checkbox
                          v-model="row.choice_option"
                          :class="$vuetify.breakpoint.smAndUp ? 'title-questions' : ''"
                          :label="row.text"
                          color="info2"
                          @change="addPkOptions(row.pk)"
                        />
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <v-layout
                    justify-space-between
                    mt-2
                  >
                    <v-flex xs6>
                      <p
                        style="cursor: pointer;"
                        class="delete-text"
                        @click="cancelApplication('Questions')"
                      >
                        <v-btn
                          style="margin-right: 0px;"
                          icon
                        >
                          <v-icon
                            style="margin: 0px; margin-bottom: 5px"
                            icon
                            color="#E57373"
                            size="18"
                          >
                            far fa-trash-alt
                          </v-icon>
                        </v-btn>
                        Cancelar postulación
                      </p>
                    </v-flex>
                    <v-layout>
                      <v-flex>
                        <v-btn
                          color="#30B0BF"
                          flat
                          large
                          @click="backButtonPressed('Questions', '1')"
                        >
                          <v-icon
                            left
                            dark
                            color="#30B0BF"
                            size="20px"
                          >
                            far fa-chevron-left
                          </v-icon>
                          <p class="cancel-text">
                            Atrás
                          </p>
                        </v-btn>
                      </v-flex>
                      <v-flex ml-5>
                        <v-btn
                          color="#30B0BF"
                          style="border-radius: 6px;"
                          large
                          :disabled="cantApply || hasAnswers"
                          @click="submitQuestions()"
                        >
                          <p class="save-text">
                            Continuar
                          </p>
                          <v-icon
                            right
                            color="#FFFFFF"
                            size="20px"
                          >
                            far fa-chevron-right
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </template>
                <template v-else>
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      mt-2
                    >
                      <v-flex>
                        <v-btn
                          color="#30B0BF"
                          large
                          flat
                          block
                          @click="backButtonPressed('Questions', '1')"
                        >
                          <v-icon
                            left
                            dark
                            color="#30B0BF"
                            size="20px"
                          >
                            far fa-chevron-left
                          </v-icon>
                          <p class="cancel-text">
                            Atrás
                          </p>
                        </v-btn>
                      </v-flex>
                      <v-btn
                        color="#30B0BF"
                        style="border-radius: 6px;"
                        large
                        block
                        :disabled="cantApply || hasAnswers"
                        @click="submitQuestions()"
                      >
                        <p class="save-text">
                          Continuar
                        </p>
                        <v-icon
                          right
                          color="#FFFFFF"
                          size="20px"
                        >
                          far fa-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout justify-center>
                        <p
                          style="cursor: pointer;"
                          class="delete-text"
                          @click="cancelApplication('Questions')"
                        >
                          <v-btn
                            style="margin-right: 0px;"
                            icon
                          >
                            <v-icon
                              style="margin: 0px; margin-bottom: 5px"
                              icon
                              color="#E57373"
                              size="18"
                            >
                              far fa-trash-alt
                            </v-icon>
                          </v-btn>
                          Cancelar postulación
                        </p>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
              </v-layout>
            </v-container>
          </v-layout>
        </v-card>
        <v-card
          v-show="steps === '3'"
          class="card-postulation"
          :style="$vuetify.breakpoint.smAndUp ? 'margin-top: 35px' : 'margin-top: 20px'"
        >
          <v-layout>
            <v-container style="max-width: 730px">
              <v-layout
                row
                wrap
              >
                <v-flex mt-4>
                  <p
                    class="title-card"
                    :style="$vuetify.breakpoint.smAndUp
                      ? 'font-size: 24px' : 'font-size: 20px'"
                  >
                    Vista previa
                  </p>
                </v-flex>
                <v-container>
                  <v-layout wrap>
                    <v-layout
                      v-if="!hasWageQuestion"
                      wrap
                    >
                      <v-flex
                        xs12
                      >
                        <p
                          class="title-questions"
                          :style="$vuetify.breakpoint.smAndUp
                            ? 'font-size: 20px' : 'font-size: 15px'"
                        >
                          Pretensión de Renta
                        </p>
                      </v-flex>
                      <v-flex
                        xs3
                      >
                        <p class="text-answer">
                          {{ wages.min }}
                        </p>
                      </v-flex>
                    </v-layout>
                    <v-flex
                      v-for="question_obj in questions"
                      :key="question_obj.uuid"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ question_obj.question }}
                      </p>
                      <p class="text-answer">
                        {{ question_obj.answer }}
                      </p>
                    </v-flex>
                    <v-flex
                      v-for="(rq, index) in offer.range_questions"
                      :key="`range_questions-${index}`"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ rq.text }}
                      </p>
                      <p
                        v-for="(option, idx) in rq.options"
                        :key="`range_questions_${index}_option_${idx}`"
                        class="text-answer"
                      >
                        <template v-if="textRangeQuestion(option.pk)">
                          {{ option.text }}
                        </template>
                      </p>
                    </v-flex>
                    <v-flex
                      v-for="(rq, index) in offer.multi_choice_questions"
                      :key="`multi_choice_questions-${index}`"
                      xs12
                      mt-3
                    >
                      <p
                        class="title-questions mb-3"
                        :style="$vuetify.breakpoint.smAndUp
                          ? 'font-size: 20px' : 'font-size: 15px'"
                      >
                        {{ rq.text }}
                      </p>
                      <p
                        v-for="(row, idoptions) in rq.multi_options"
                        :key="`multiple_question_${index}_multi_options_${idoptions}`"
                        class="text-answer"
                      >
                        <template v-if="textMultipleQuestion(row.pk)">
                          {{ row.text }}
                        </template>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-layout>
              <template v-if="$vuetify.breakpoint.smAndUp">
                <v-layout
                  justify-space-between
                  mt-5
                >
                  <v-flex xs6>
                    <p
                      style="cursor: pointer;"
                      class="delete-text"
                      @click="cancelApplication('Review')"
                    >
                      <v-btn
                        style="margin-right: 0px;"
                        icon
                      >
                        <v-icon
                          style="margin: 0px; margin-bottom: 5px"
                          icon
                          color="#E57373"
                          size="18"
                        >
                          far fa-trash-alt
                        </v-icon>
                      </v-btn>
                      Cancelar postulación
                    </p>
                  </v-flex>
                  <v-layout>
                    <v-flex>
                      <v-btn
                        color="#30B0BF"
                        flat
                        large
                        @click="backButtonPressed('Review', '2')"
                      >
                        <v-icon
                          left
                          dark
                          color="#30B0BF"
                          size="20px"
                        >
                          far fa-chevron-left
                        </v-icon>
                        <p class="cancel-text">
                          Atrás
                        </p>
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-btn
                        color="#30B0BF"
                        style="border-radius: 6px;"
                        large
                        @click="applyForJob()"
                      >
                        <p class="save-text">
                          Enviar postulación
                        </p>
                        <v-icon
                          right
                          color="#FFFFFF"
                          size="20px"
                        >
                          far fa-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </template>
              <template v-else>
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    mt-5
                  >
                    <v-flex>
                      <v-btn
                        color="#30B0BF"
                        large
                        flat
                        block
                        @click="backButtonPressed('Review', '2')"
                      >
                        <v-icon
                          left
                          dark
                          color="#30B0BF"
                          size="20px"
                        >
                          far fa-chevron-left
                        </v-icon>
                        <p class="cancel-text">
                          Atrás
                        </p>
                      </v-btn>
                    </v-flex>
                    <v-btn
                      color="#30B0BF"
                      style="border-radius: 6px;"
                      large
                      block
                      @click="applyForJob()"
                    >
                      <p class="save-text">
                        Enviar postulación
                      </p>
                      <v-icon
                        right
                        color="#FFFFFF"
                        size="20px"
                      >
                        far fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout justify-center>
                      <p
                        style="cursor: pointer;"
                        class="delete-text"
                        @click="cancelApplication('Review')"
                      >
                        <v-btn
                          style="margin-right: 0px;"
                          icon
                        >
                          <v-icon
                            style="margin: 0px; margin-bottom: 5px"
                            icon
                            color="#E57373"
                            size="18"
                          >
                            far fa-trash-alt
                          </v-icon>
                        </v-btn>
                        Cancelar postulación
                      </p>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </v-container>
          </v-layout>
        </v-card>
      </v-flex>
    </v-container>
    <v-layout
      v-else
      align-center
      justify-center
      row
      fill-height
    >
      <v-layout
        column
        align-center
      >
        <img
          style="margin-bottom: 50px"
          src="@/assets/Group.svg"
          alt="logo"
        >
        <template v-if="$vuetify.breakpoint.smAndUp">
          <p
            class="postulation-complete"
          >
            ¡Postulación exitosa!
          </p>
          <p
            class="text-complete"
          >
            Ya estás postulando al cargo de "{{ offer.position }}". <br>
            Pronto nos pondremos en contacto contigo para informarte de tu avance en el proceso.
          </p>
          <div style="display: inline-flex">
            <p class="text-complete">
              Revisa el estado de tu postulación en la sección
            </p>
            <router-link
              :to="{name: 'candidate:profile:applications'}"
              class="text-complete-postulation"
            >
              "Mis Postulaciones".
            </router-link>
          </div>
        </template>
        <template v-else>
          <p
            class="postulation-complete-responsive"
          >
            ¡Postulación exitosa!
          </p>
          <p
            :class="$vuetify.breakpoint.smAndUp
              ? 'text-complete' : 'text-complete-responsive'"
          >
            Ya estás postulando al cargo de "{{ offer.position }}".
            <br>
            Pronto nos pondremos en contacto contigo para informarte de tu avance en el proceso.
            Revisa el estado de tu postulación en la sección
          </p>
          <router-link
            :to="{name: 'candidate:profile:applications'}"
            class="text-complete-postulation-responsive"
          >
            Ver mis postulaciones
          </router-link>
        </template>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import { JwtIsExpired } from '@/utils/jwt';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import CountryPicker from '@/components/inputs/CountryPicker.vue';
import RegionPicker from '@/components/inputs/RegionPicker.vue';
import CityPicker from '@/components/inputs/CityPicker.vue';

export default {
  name: 'CandidatePostulation',
  components: {
    CountryPicker,
    CityPicker,
    RegionPicker,
  },
  mixins: [JWTConfigMixin],
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      steps: 0,
      first_step_complete: false,
      application_completed: false,
      offer: [],
      questions: [],
      pk_options: [],
      userDetails: [],
      phone_number: '',
      country: '',
      region: '',
      city: '',
      cv: null,
      cvName: '',
      wages: {
        min: 0,
        max: 0,
      },
      nationalityErrors: [],
      phoneErrors: [],
      cityErrors: [],
      cvError: false,
    };
  },
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
    currencyPrefix() {
      return (this.offer && this.offer.country_code === 'PE') ? 'S/' : '$';
    },
    hasAnswers() {
      if (this.offer.multi_choice_questions.length > 0) {
        const options = this.offer.multi_choice_questions.map(data => {
          const copy = { ...data };
          copy.multi_options = copy.multi_options.map(option => {
            const copyTwo = { ...option };
            return copyTwo.pk;
          });
          return this.pk_options.some(pk => copy.multi_options.includes(pk));
        });
        return options.some(data => data === false);
      }
      return false;
    },
    hasWageQuestion() {
      const regexp = RegExp('pretensi(.)+renta');
      let res = false;
      this.questions.forEach(el => {
        if (regexp.test(el.question)) {
          res = true;
        }
      });
      return res;
    },
    cantApply() {
      // returns true if user hasn't answered all range questions
      const selectedOptions = this.payload.range_answers.map(data => data.option);
      return !selectedOptions.reduce((accumulator, option) => accumulator && option, true);
    },
    payload() {
      return {
        answers: this.questions.map(questionObj => ({
          answer: questionObj.answer,
          question: questionObj.uuid,
        })),
        range_answers: this.offer.range_questions ? this.offer.range_questions.map(data => {
          if (data.yesno_option) {
            return {
              option: data.yesno_option,
            };
          } if (data.likert_option) {
            return {
              option: data.likert_option ? data.options[data.likert_option].pk : '',
            };
          }
          return {};
        }) : [],
        wages: this.wages,
        multiple_questions_answers: this.pk_options ? this.pk_options : [],
      };
    },
    numberPrefix() {
      if (this.country === 'CL') {
        return '+56';
      } if (this.country === 'PE') {
        return '+51';
      } if (this.country === 'CO') {
        return '+57';
      }
      return '';
    },
    jointNumber() {
      return `${this.numberPrefix}${this.phone_number}`;
    },
  },
  watch: {
    userDetails() {
      this.setExtras();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOffer();
      this.loadSelf();
      this.setExtras();
    });
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
    addPkOptions(data) {
      if (this.pk_options.includes(data)) {
        const index = this.pk_options.indexOf(data);
        if (index > -1) {
          this.pk_options.splice(index, 1);
        }
      } else {
        this.pk_options.push(data);
      }
    },
    setExtras() {
      this.country = this.userDetails.city_region.country;
      this.region = this.userDetails.city_region.uuid;
      this.city = this.userDetails.city_uuid;
      this.cv = this.userDetails.cv;
      if (this.userDetails.cv) {
        const pathArr = this.userDetails.cv.split('/');
        this.cvName = pathArr[pathArr.length - 1].split('?').shift();
      }
      if (this.userDetails.phone_number) {
        this.phone_number = this.userDetails.phone_number.slice(3);
      } else {
        this.phone_number = '';
      }
    },
    pickCvFile() {
      this.$refs.cvFileField.click();
    },
    onCvFilePicked(e) {
      this.cv = e.target.files[0] !== undefined ? e.target.files[0] : null;
      this.cvName = e.target.files[0] !== undefined ? e.target.files[0].name : '';
    },
    jobIcon() {
      if (!this.offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[this.offer.transformed_area] ? iconDict[this.offer.transformed_area]
        : 'fal fa-question-circle';
    },
    textRangeQuestion(pk) {
      return this.payload.range_answers.some(data => data.option === pk);
    },
    textMultipleQuestion(pk) {
      return this.payload.multiple_questions_answers.some(data => data === pk);
    },
    loadOffer() {
      this.$store.commit('setLoading');
      const endpoint = this.$store.state.endpoints.offers.public.get(this.$route.params.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.offer = response.data;
          this.$vuetify.goTo(0, {
            duration: 1000,
            offset: 0,
            easing: 'easeOutCubic',
          });
          this.jsonLd = {
            '@context': 'https://schema.org',
            '@type': 'JobPosting',
            datePosted: this.offer.published_date,
            description: this.offer.description,
            hiringOrganization: {
              '@type': 'Organization',
              name: 'Mandomedio',
              sameAs: 'https://mandomedio.com/',
              logo: '/favicon.png',
            },
            jobLocation: {
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                addressLocality: this.offer.city_name,
                addressCountry: this.offer.country_code,
              },
            },
            title: this.offer.position,
            employmentType: this.offer.contract_type_text,
          };
          const epstudies = `${this.$store.state.endpoints.candidates.studies}?country=${this.offer.country_code}`;
          axios.get(epstudies, this.jwtConfig)
            .then(resp => {
              const prot = resp.data;
              /*
               * This next line takes a key: value Object and inverts it into a value: key
               * Object, which for computing simplicity is used as a Map instead.
               * Reference:
               * https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Object/entries
               */
              this.studies = new Map(Object.entries(prot).map(el => [el[1], el[0]]));
              this.$store.commit('unsetLoading');
              this.questions = this.offer.questions.map(data => {
                const copy = { ...data };
                copy.answer = '';
                return copy;
              });
            });
          this.offer_state = 'ACTIVE';
        }).catch(e => {
          console.log(e.response);
          this.offer_state = 'NOT_FOUND';
        });
    },
    submitDetails() {
      this.nationalityErrors = [];
      this.phoneErrors = [];
      this.cityErrors = [];
      this.cvError = false;
      this.$store.commit('setLoading');
      const fd = new FormData();
      fd.append('email', this.email);
      fd.append('city', this.city);
      if (this.phone_number !== '') {
        fd.append('phone_number', this.jointNumber);
      } else {
        fd.append('phone_number', this.phone_number);
      }
      if (!(typeof this.cv === 'string')) {
        fd.append('cv', this.cv);
      }
      fd.append('email', this.userDetails.email);
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('unsetLoading');
          this.loadSelf();
          this.steps = '2';
          this.first_step_complete = true;
          this.gaEvent('Completed Personal Data', 'offers', this.$route.params.uuid, 1);

          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          if (err.response.data.nationality) {
            this.nationalityErrors = [...err.response.data.nationality];
          }
          if (err.response.data.phone_number) {
            this.phoneErrors = [...err.response.data.phone_number];
          }
          if (err.response.data.city) {
            this.cityErrors = [...err.response.data.city];
          }
          if (err.response.data.cv) {
            this.cvError = true;
          }
        });
    },
    submitQuestions() {
      this.steps = '3';
      this.gaEvent('Answered Questions', 'offers', this.$route.params.uuid, 1);
      this.gaEvent('Application Reviewed', 'offers', this.$route.params.uuid, 1);
    },
    cancelApplication(name) {
      let action = 'Aplication Canceled in ';
      action += name;
      this.gaEvent(action, 'offers', this.$route.params.uuid, 1);
      this.$router.back();
    },
    backButtonPressed(name, step) {
      let action = 'Back Button in ';
      action += name;
      this.gaEvent(action, 'offers', this.$route.params.uuid, 1);
      this.steps = step;
    },
    applyForJob() {
      this.$store.commit('setLoading');
      axios.post(this.$store.state.endpoints.offers.candidates.apply(this.$route.params.uuid),
        this.payload,
        this.jwtConfig).then(() => {
        this.$store.commit('unsetLoading');
        this.gaEvent('Application Sent', 'offers', this.$route.params.uuid, 1);
        this.application_completed = true;
      }).catch(error => {
        this.application_completed = false;
        this.$store.commit('unsetLoading');
        this.$store.commit('adminSnackbarUp', error.response);
      });
    },
    loadSelf() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.candidates.getSelf,
        this.jwtConfig)
        .then(resp => {
          this.userDetails = resp.data;
          this.$store.commit('unsetLoading');
        })
        .catch(() => {
          this.$router.push({ name: 'candidate:offers' });
        });
    },
  },
};
</script>

<style scoped>
.navigation-menu{
  background-color: #f9f9f9;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #F0EFF3;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #F0EFF3;
}
.menu-highlight {
  background-color: #f1f1f1 !important;
  border-radius: 6px;
  height: 60px;
  width: 220px;
  color: #EE8146 !important;
}
.list-tile-title {
  height: 32px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.14px;
  line-height: 32px;
}
.list-tile-title-highlight{
  height: 32px;
  color: #EE8146;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px
}
.list-tile-margin{
  margin-left: 16px;
  margin-right: 16px;
  height: 60px;
}
.title-postulation{
  width: 479px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.title-postulation-responsive {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
}
.card-postulation{
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.notification_postulation {
  border-radius: 6px;
  background-color: rgba(48,176,191,0.1);
}
.text-notification {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 17px;
  text-align: justify;
}
.text-notification-company {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 17px;
  text-align: justify;
  margin: 0px;
  margin-top: 7px;
}
.text-notification-company-responsive {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 17px;
  text-align: justify;
  margin: 0px;
}
.title-card {
  height: 32px;
  width: 211px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 32px;
}
.inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
.upload-cv {
  box-sizing: border-box;
  height: 110px;
  width: auto;
  border: 1px solid #30B0BF;
  border-radius: 6px;
  background-color: rgba(48,176,191,0.07);
  cursor: pointer;
}
.upload-cv-error {
  box-sizing: border-box;
  height: 110px;
  width: auto;
  border: 1px solid #FF7573;
  border-radius: 6px;
  background-color: rgba(191, 48, 48, 0.07);
  cursor: pointer;
}
.upload-cv-text {
  height: 22px;
  color: #30B0BF;
  font-family: Lato;
  font-size: 15.5px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 25px;
  margin-left: 12px;
  cursor: pointer;
}
.upload-cv-text-error {
  height: 22px;
  color: #FF7573;
  font-family: Lato;
  font-size: 15.5px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 25px;
  margin-left: 12px;
  cursor: pointer;
}
.formnat-cv {
  height: 20px;
  color: rgba(0,0,0,0.30);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.11px;
  line-height: 24px;
  text-align: right;
}
.formnat-cv-error {
  height: 20px;
  color: #FF7573;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  margin-left: 14px;
}
.formnat-cv-responsive {
  height: 20px;
  color: rgba(0,0,0,0.30);
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  text-align: right;
  margin: 0px;
  margin-right: 2px;
}
.formnat-cv-responsive-error {
  height: 20px;
  color:#FF7573;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  margin: 0px;
  margin-left: 14px;
}
.title-basic-information {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 18px;
  letter-spacing: 0.14px;
}
.save-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
  text-transform: initial;
}
.cancel-text {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  text-transform: initial;
  margin: 0px;
}
.delete-text {
  color: #E57373;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-transform: initial;
  margin: 0px;
  margin-top: 3px;
}
.postulation-complete {
  height: 42px;
  width: 334px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
}
.postulation-complete-responsive {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.text-complete {
  color: #737373;
  font-family: Lato;
  font-size: 17px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
}
.text-complete-responsive {
  height: 110px;
  width: 256px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 22px;
  text-align: center;
}
.text-complete-postulation {
  color: #EE8146;
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  margin-left: 3.15px;
  text-decoration: none;
}
.text-complete-postulation-responsive {
  height: 26px;
  width: 145px;
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  margin-top: 50px;
  text-decoration: none;
}
.title-questions {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.text-answer {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;
}
</style>
